@tailwind base;
@tailwind components;
@tailwind utilities;


:root {
  --primary-color: #7DCD85;
  --secondary-color: #132B64;
  --neutral-color: #504F4F;
}

body {
    font: 11px "Century Gothic", Futura, sans-serif;
    margin: 20px;
  }
  
  ol, ul {
    padding-left: 30px;
  }
  
  .board-row:after {
    clear: both;
    content: "";
    display: table;
  }
  
  .status {
    margin-bottom: 10px;
  }
  
  .square {
    background: #fff;
    border: 1px solid #999;
    float: left;
    font-size: 24px;
    font-weight: bold;
    line-height: 34px;
    height: 34px;
    margin-right: -1px;
    margin-top: -1px;
    padding: 0;
    text-align: center;
    width: 34px;
  }
  
  .square:focus {
    outline: none;
  }
  
  .kbd-navigation .square:focus {
    background: #ddd;
  }
  
  .game {
    display: flex;
    flex-direction: row;
  }
  
  .game-info {
    margin-left: 20px;
  }



.box {
  background-color: transparent;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.box .rectangle-wrapper {
  border: 0px none;
  height: 70px;
}

.box .rectangle {
  background-color: #E6EDFE;
  height: 70px;
  left: 0;
  position: fixed;
  top: 0;
}

.section-header {
  border: 0px none;
  height: 24px;
}

.section-header .text-wrapper-h3 {
  color: #504F4F;
  font-family: Arial;
  font-size: 14px;
  font-weight: 600;
}

.class-enrty-container {
  border: 0px none;
}

.class-enrty-container .class-enrty-content-wrapper {
  background-color: #3268f51f;
  border-radius: 15px;
}

.text-wrapper {
  color: #504F4F;
  font-family: "Poppins-Regular", Helvetica;
}

.horse-title {
  color: #504F4F;
}

.header-label {
  border: 0px none;
  height: 36px;
  width: 77px;
}

.header-label .header-text-wrapper {
  color: var(--secondary-color);
  font-family: "Oswald-SemiBold", Helvetica;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
}